import { Flex, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import GoogleMapReact from "google-map-react"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { scroller } from "react-scroll"
import { useAvailableStores } from "~/hooks/useAvailableStores"
import { useCart } from "~/hooks/useCart"
import { useSelectedStore } from "~/hooks/useSelectedStore"
import { actions } from "~/redux/process"
import StoreUpdateComfirmationModal from "../StoreUpdateConfirmationModal"
import StoreMarker from "./../StoreMarker"

interface Props {}

const StoreMapGoogle: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { cartActions } = useCart()
  const { store: selectedStore } = useSelectedStore()
  const { stores: availableStores } = useAvailableStores()
  const [lineItemsToKeep, setLineItemsToKeep] = useState<EntityId[]>([])
  const [lineItemsToRemove, setLineItemsToRemove] = useState<EntityId[]>([])
  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()

  const onOpen = useCallback(
    (data: { lineItemsToKeep: EntityId[]; lineItemsToRemove: EntityId[] }) => {
      setLineItemsToKeep(data.lineItemsToKeep)
      setLineItemsToRemove(data.lineItemsToRemove)
      openDialog()
    },
    [openDialog]
  )

  const onClose = useCallback(() => {
    closeDialog()
    setLineItemsToKeep([])
    setLineItemsToRemove([])
  }, [closeDialog])

  const onSelectStore = useCallback(
    (storeId: EntityId) => {
      cartActions.setStore({ storeId, onOpen, onClose })
      scroller.scrollTo(`StoreSearchListItem-${storeId}`, {
        containerId: "StoreSearchList",
        duration: 500,
        smooth: true,
      })
    },
    [cartActions, onClose, onOpen]
  )

  const onDialogDecision = useCallback(
    choice => {
      dispatch(actions.productCompatibilityDialog({ choice }))
    },
    [dispatch]
  )

  return (
    <Flex
      flex={3}
      height={{ base: selectedStore ? 320 : "full", md: "full" }}
      borderTopRightRadius={{ base: "none", md: "lg" }}
      borderBottomRightRadius={{ base: "none", md: "lg" }}
      overflow="hidden"
    >
      {selectedStore && (
        <GoogleMapReact
          center={{
            lat: +selectedStore.latitude,
            lng: +selectedStore.longitude,
          }}
          defaultZoom={17}
        >
          {availableStores.map(store => {
            return (
              <StoreMarker
                key={store.id}
                onClick={() => onSelectStore(store.id)}
                lat={+store.latitude}
                lng={+store.longitude}
                label={store.name}
                selected={store.id === selectedStore.id}
              />
            )
          })}
        </GoogleMapReact>
      )}

      <StoreUpdateComfirmationModal
        lineItemsToKeep={lineItemsToKeep}
        lineItemsToRemove={lineItemsToRemove}
        onClose={() => onDialogDecision(false)}
        onConfirm={() => onDialogDecision(true)}
        isOpen={isOpen}
      />
    </Flex>
  )
}

export default StoreMapGoogle
