import { ButtonProps } from "@chakra-ui/button"
import React from "react"
import { useSettings } from "~/hooks/useSettings"
import StoreSearchUseMyLocationButtonAWS from "./aws/StoreSearchUseMyLocationButtonAWS"
import StoreSearchUseMyLocationButtonGoogle from "./google/StoreSearchUseMyLocationButtonGoogle"

interface Props extends ButtonProps {}

const StoreSearchUseMyLocationButton: React.FC<Props> = props => {
  const { settings } = useSettings()
  switch (settings.mapProvider) {
    case "googleMaps":
      return <StoreSearchUseMyLocationButtonGoogle {...props} />
    case "amazonLocation":
    default:
      return <StoreSearchUseMyLocationButtonAWS {...props} />
  }
}

export default React.memo(StoreSearchUseMyLocationButton)
