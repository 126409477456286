import { uniq } from "lodash"
import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { useDebounce } from "react-use"
import { useCart } from "~/hooks/useCart"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { lineItemsSelectors } from "~/redux/state/lineItems"
import { printServiceProductsSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"
import { GetStoreAutocompleteParams } from "~/services/PrinticularApi"
import { PrintServiceProductEntityHydrated } from "@jackfruit/common"
import { usePage } from "~/hooks/usePage"
import iso3166CountryCodes from "~/data/iso3166CountryCodes"
import { AddressLocationEntity } from "~/interfaces/entities/AddressLocation"
import { usePrintServices } from "~/hooks/usePrintServices"

export const usePrinticularAutoComplete = () => {
  const api = usePrinticularApi()
  const { cart } = useCart()
  const { page } = usePage()

  const lineItems = useSelector<RootState, LineItemEntity[]>(state =>
    lineItemsSelectors.selectByIds(state, cart.lineItemIds)
  )
  const productIds = uniq(
    lineItems
      .filter(({ isReady }) => isReady)
      .map(lineItem => lineItem.productId)
  )
  const products = useSelector<RootState, PrintServiceProductEntityHydrated[]>(
    state => printServiceProductsSelector.selectByIds(state, productIds!)
  )
  const remoteProductIds = uniq(products.map(product => product.remoteId))
    .sort()
    .join(",")

  // get all print services for page session
  const { printServices } = usePrintServices(
    page.printServices[cart.fulfillment].map(data => data.id)
  )
  const remotePrintServiceIds = printServices
    .map(printService => printService.remoteId)
    .sort()
    .join(",")

  const countryCodes = page.territories[cart.fulfillment]
  const threeDigitsCountryCodes = uniq(
    countryCodes.map(code => iso3166CountryCodes[code])
  )
    .sort()
    .join(",")

  const [currentValue, setCurrentValue] = useState("")
  const [data, setData] = useState<AddressLocationEntity[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchActive, setIsFetchActive] = useState(true)

  const setValue = useCallback((newValue, shouldFetchData = true) => {
    setIsFetchActive(shouldFetchData)
    setCurrentValue(newValue)
  }, [])

  const clearSuggestions = useCallback(() => {
    setData([])
  }, [])

  //if Locale is Japan, allow the user to type in only two characters
  const minimumInput = threeDigitsCountryCodes === "JPN" ? 1 : 3

  // perform actual search
  useDebounce(
    async () => {
      if (currentValue.length > minimumInput && isFetchActive) {
        setIsLoading(true)
        const params: GetStoreAutocompleteParams = {
          filter: {
            country: threeDigitsCountryCodes,
            locationLimit: 10,
            storeLimit: 10,
            printServices: remotePrintServiceIds,
            radius: 100,
            text: currentValue,
          },
        }

        if (remoteProductIds) {
          params.filter.productIds = remoteProductIds
        }

        const result = await api.getStoreAutocomplete(params)

        const newData = result.map(someResult => {
          const labels = someResult.label.split(",")
          const mainText = labels[0]
          const secondaryText = labels.slice(1, labels.length).join(", ")

          return {
            ...someResult,
            place_id: someResult.id,
            structured_formatting: {
              main_text: mainText,
              secondary_text: secondaryText,
            },
          }
        })

        setData(newData)
        setIsLoading(false)
      }
    },
    1000,
    [currentValue, isFetchActive]
  )

  return { value: currentValue, setValue, data, isLoading, clearSuggestions }
}
