import { PrintServiceProductEntityHydrated } from "@jackfruit/common"
import { uniq } from "lodash"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import iso3166CountryCodes from "~/data/iso3166CountryCodes"
import { useCart } from "~/hooks/useCart"
import { usePage } from "~/hooks/usePage"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { usePrinticularApiV2 } from "~/hooks/usePrinticularApiV2"
import { usePrintServices } from "~/hooks/usePrintServices"
import { LatLng } from "~/interfaces/entities/LatLng"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { lineItemsSelectors } from "~/redux/state/lineItems"
import { printServiceProductsSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"
import { GetStoreAutocompleteParams } from "~/services/PrinticularApi"

export const usePrinticularGeocoder = () => {
  const api = usePrinticularApi()
  const apiV2 = usePrinticularApiV2()
  const { cart } = useCart()
  const { page } = usePage()

  const lineItems = useSelector<RootState, LineItemEntity[]>(state =>
    lineItemsSelectors.selectByIds(state, cart.lineItemIds)
  )

  const productIds = uniq(
    lineItems
      .filter(({ isReady }) => isReady)
      .map(lineItem => lineItem.productId)
  )
  const products = useSelector<RootState, PrintServiceProductEntityHydrated[]>(
    state => printServiceProductsSelector.selectByIds(state, productIds!)
  )
  const remoteProductCodes = uniq(products.map(product => product.productCode))
  const remoteProductIds = uniq(products.map(product => product.remoteId))

  // get all print services for page session
  const { printServices } = usePrintServices(
    page.printServices[cart.fulfillment].map(data => data.id)
  )
  const remotePrintServiceIds = printServices.map(
    printService => printService.remoteId
  )

  const countryCodes = page.territories[cart.fulfillment]
  const threeDigitsCountryCodes = uniq(
    countryCodes.map(code => iso3166CountryCodes[code])
  ).join(",")

  const getGeocode = useCallback(
    async (latLng: LatLng) => {
      const stores = await apiV2.getAvailableStoresForMultiplePrintServices(
        latLng,
        remotePrintServiceIds,
        remoteProductCodes
      )

      if (stores.length > 0) {
        return stores[0].address
      } else {
        return ""
      }
    },
    [apiV2, remotePrintServiceIds, remoteProductCodes]
  )

  const getLatLng = useCallback(
    async (place: string) => {
      const params: GetStoreAutocompleteParams = {
        filter: {
          country: threeDigitsCountryCodes,
          locationLimit: 10,
          storeLimit: 6,
          printServices: remotePrintServiceIds.join(","),
          radius: 100,
          text: place,
        },
      }

      if (remoteProductIds) {
        params.filter.productIds = remoteProductIds.join(",")
      }

      const stores = await api.getStoreAutocomplete(params)
      if (stores.length) {
        const firstStore = stores[0]
        return {
          lat: firstStore.latitude,
          lng: firstStore.longitude,
        }
      }

      return {
        lat: 0,
        lng: 0,
      }
    },
    [api, remotePrintServiceIds, remoteProductIds, threeDigitsCountryCodes]
  )

  return { getGeocode, getLatLng }
}
