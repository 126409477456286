import { Icon, Spinner } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiSearch, FiX } from "react-icons/fi"

interface Props {
  isLoading: boolean
  isEmpty: boolean
  onClear: () => void
}

const StoreSearchInputActionIcon: React.FC<Props> = ({
  isLoading,
  isEmpty,
  onClear,
}) => {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <Icon
        as={Spinner}
        color="primary.400"
        aria-label={t(
          "components.store-search.StoreSearchInputActionIcon.Loading"
        )}
      />
    )
  }

  if (!isEmpty) {
    return (
      <Icon
        as={FiX}
        boxSize="1.1em"
        color="gray.300"
        aria-label={t(
          "components.store-search.StoreSearchInputActionIcon.Clear"
        )}
        onClick={onClear}
        _hover={{ cursor: "pointer", color: "primary.400" }}
      />
    )
  }

  return (
    <Icon
      as={FiSearch}
      color="gray.400"
      aria-label={t(
        "components.store-search.StoreSearchInputActionIcon.Search"
      )}
    />
  )
}

export default StoreSearchInputActionIcon
