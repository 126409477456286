import { useCallback, useState } from "react"
import { useEffectOnce } from "react-use"
import { getCurrentPosition } from "~/services/Utils"

export enum GeolocationStatus {
  Pending = 0,
  Allowed = 1,
  Blocked = 2,
}

export const useMyGeolocation = () => {
  const [permissionStatus, setPermissionStatus] = useState<GeolocationStatus>(
    GeolocationStatus.Pending
  )

  const checkPermissionStatus = async () => {
    if ("permissions" in navigator) {
      const permission = await navigator?.permissions?.query({
        name: "geolocation",
      })
      permission.onchange = () => {
        switch (permission?.state) {
          case "granted":
            setPermissionStatus(GeolocationStatus.Allowed)
            break
          case "denied":
            setPermissionStatus(GeolocationStatus.Blocked)
            break
          default:
            setPermissionStatus(GeolocationStatus.Pending)
            break
        }
      }
    } else {
      // Safari support, navigator.permission is not yet supported
      setPermissionStatus(GeolocationStatus.Allowed)
    }
  }

  useEffectOnce(() => {
    checkPermissionStatus()
  })

  const findMe = useCallback(async () => {
    const result = await getCurrentPosition()
    return result
  }, [])

  return {
    findMe,
    permissionStatus,
  }
}
