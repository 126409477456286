import React from "react"
import { useSettings } from "~/hooks/useSettings"
import StoreSearchInputAWS from "./aws/StoreSearchInputAWS"
import StoreSearchInputGoogle from "./google/StoreSearchInputGoogle"

interface Props {}

const StoreSearchInput: React.FC<Props> = props => {
  const { settings } = useSettings()
  switch (settings.mapProvider) {
    case "googleMaps":
      return <StoreSearchInputGoogle {...props} />
    case "amazonLocation":
    default:
      return <StoreSearchInputAWS {...props} />
  }
}

export default StoreSearchInput
