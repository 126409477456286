import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useTheme,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  title: string
  description: string
  isOpen: boolean
  onClose: () => void
}

const InfoModal: React.FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()

  const {
    colors: { gray },
  } = useTheme()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding={3}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="gray"
            onClick={onClose}
            _focus={{
              boxShadow: `${gray[400]} 0 0 0 3px`,
            }}
          >
            {t("components.InfoModal.Action")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InfoModal
