import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import ErrorPanel from "../ErrorPanel"
import { ReactComponent as StoreSelectorGraphicSvg } from "~/images/StoreSelectorGraphic.svg"
import { t } from "i18next"

interface Props {}

const message = t("components.store-search.StoreListEmpty.Message")

const StoreListEmpty: React.FC<Props> = () => {
  return (
    <Flex
      h="full"
      direction="column"
      justifyContent="center"
      pb={{ base: "0", lg: "8" }}
    >
      <Box
        maxW={{ base: "14rem", lg: "18rem" }}
        mx="auto"
        mb={{ base: "1rem", lg: "2rem" }}
      >
        <StoreSelectorGraphicSvg width="100%" />
      </Box>
      <ErrorPanel error={message} mb={0} />
    </Flex>
  )
}

export default StoreListEmpty
