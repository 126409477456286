import React from "react"
import { useSettings } from "~/hooks/useSettings"
import StoreMapAWS from "./aws/StoreMapAWS"
import StoreMapGoogle from "./google/StoreMapGoogle"

interface Props {}

const StoreMap: React.FC<Props> = () => {
  const { settings } = useSettings()

  switch (settings.mapProvider) {
    case "googleMaps":
      return <StoreMapGoogle />
    case "amazonLocation":
    default:
      return <StoreMapAWS />
  }
}

export default StoreMap
