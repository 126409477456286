import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as StoreSelectorGraphicSvg } from "~/images/StoreSelectorGraphic.svg"
import StoreSearchInput from "./StoreSearchInput"
import StoreSearchUseMyLocationButton from "./StoreSearchUseMyLocationButton"

interface Props {}

const StoreSearchForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <Flex
      direction="column"
      justifyContent="center"
      py={10}
      paddingRight={{ base: 0, md: 4 }}
    >
      <Box maxW={{ base: "14rem", lg: "18rem" }} mx="auto" mb={0}>
        <StoreSelectorGraphicSvg width="100%" />
      </Box>
      <Heading textAlign="center" as="h3" size="sm" marginTop={4}>
        {t("components.store-search.StoreSearchFormEmpty.Title")}
      </Heading>
      <Text fontSize="sm" textAlign="center" marginTop={2} maxWidth="xs">
        {t("components.store-search.StoreSearchFormEmpty.Message")}
      </Text>
      <VStack spacing={2} marginTop={4}>
        <StoreSearchUseMyLocationButton />
        <Text fontSize="sm" fontWeight="bold">
          {t("components.store-search.StoreSearchFormEmpty.Or")}
        </Text>
        <StoreSearchInput />
      </VStack>
    </Flex>
  )
}

export default StoreSearchForm
