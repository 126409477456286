import { ListItem, UnorderedList } from "@chakra-ui/react"
import { EntityId } from "@reduxjs/toolkit"
import { uniq } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLineItems } from "~/hooks/useLineItems"
import { useProducts } from "~/hooks/useProducts"
import ConfirmationModal from "../ConfirmationModal"

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  lineItemsToKeep: EntityId[]
  lineItemsToRemove: EntityId[]
}

const StoreUpdateComfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  lineItemsToKeep,
  lineItemsToRemove,
}) => {
  const { lineItems } = useLineItems(lineItemsToRemove)
  const allProductIds = uniq(lineItems.map(lineItem => lineItem.productId))
  const { products } = useProducts(allProductIds)
  const { t } = useTranslation()
  const listOfProductUnavailable = products
    .map(product => product.shortDescription)
    .sort()

  const message =
    lineItemsToKeep.length === 0
      ? t("components.store-search.StoreUpdateConfirmationModal.OutOfStock")
      : t("components.store-search.StoreUpdateConfirmationModal.LimitedStock")

  return (
    <ConfirmationModal
      title={t("components.store-search.StoreUpdateConfirmationModal.Title")}
      description={message}
      onClose={onClose}
      onConfirm={onConfirm}
      isOpen={isOpen}
    >
      {lineItemsToKeep.length > 0 && (
        <UnorderedList pl={6} mt={3}>
          {listOfProductUnavailable.map(label => {
            return <ListItem key={label}>{label}</ListItem>
          })}
        </UnorderedList>
      )}
    </ConfirmationModal>
  )
}

export default StoreUpdateComfirmationModal
