import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"
import "./ScrollView.css"

interface Props extends BoxProps {}

const ScrollView: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      className="ScrollView"
      overflowY="scroll"
      height="full"
      w="full"
    >
      {children}
    </Box>
  )
}

export default ScrollView
