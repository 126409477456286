import { VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { StoreEntity } from "~/interfaces/entities/Store"
import StoreListItem from "./StoreListItem"

interface Props {
  selectedId?: EntityId
  stores: StoreEntity[]
  onSelect: (id: EntityId) => void
}

const StoreList: React.FC<Props> = ({
  stores,
  onSelect,
  selectedId = null,
}) => {
  return (
    <VStack width="full" pb={4}>
      {stores.map(store => {
        const isSelected = selectedId === store.id
        return (
          <StoreListItem
            name={`StoreSearchListItem-${store.id}`}
            key={store.id}
            store={store}
            isSelected={isSelected}
            onClick={onSelect}
          />
        )
      })}
    </VStack>
  )
}

export default StoreList
