import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import ReactDOMServer from "react-dom/server"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { useAvailableStores } from "~/hooks/useAvailableStores"
import { useCart } from "~/hooks/useCart"
import { useProcessActions } from "~/hooks/useProcessActions"
import { ReactComponent as StoreListEmptyMapSvg } from "~/images/StoreSelectorEmptyMap.svg"
import NextButton from "../scroll-wrappers/NextButton"
import StoreMap from "../store-search/StoreMap"
import StoreSearchFormEmpty from "../store-search/StoreSearchFormEmpty"
import StoreSearchFormNotEmpty from "../store-search/StoreSearchFormNotEmpty"
import Wrapper from "../Wrapper"

interface Props {}

const StoreSearchBlock: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { cart } = useCart()
  const { stores } = useAvailableStores()
  const process = useProcessActions()
  const { storeLat, storeLng, storeId, storePlace } = cart

  const hasStores = stores.length > 0
  const hasSearchedStores = storePlace && storeLat && storeLng

  useEffectOnce(() => {
    if (storeLat && storeLng) {
      process.searchStores({})
    }
  })

  const emptyMapSvg = useMemo(
    () => btoa(ReactDOMServer.renderToString(<StoreListEmptyMapSvg />)),
    []
  )

  return (
    <Wrapper
      id="p-store-search" // GTM: store search visible
      py={16}
      hasSeparator={true}
      backgroundColor="gray.100"
    >
      <Heading as="h3" fontSize="2xl" marginBottom={2} textAlign="center">
        {t("components.blocks.StoreSearchBlock.Heading")}
      </Heading>
      <Text mb={8} textAlign="center">
        {t("components.blocks.StoreSearchBlock.Subheading")}
      </Text>
      <Flex
        bg="white"
        direction={{ base: "column", md: "row" }}
        boxShadow="md"
        borderRadius="lg"
      >
        <Flex
          width={{ base: "full", md: "50%", lg: "40%" }}
          justifyContent="center"
          padding={4}
          paddingBottom={0}
          paddingRight={{ md: 0 }}
        >
          {!hasSearchedStores && <StoreSearchFormEmpty />}
          {hasSearchedStores && (
            <Box width="full" marginBottom={0}>
              <StoreSearchFormNotEmpty />
            </Box>
          )}
        </Flex>
        <Flex width={{ base: "full", md: "50%", lg: "60%" }}>
          {!Boolean(storeId) && (
            <Box
              display={{ base: "none", md: "block" }}
              w="full"
              backgroundImage={`url(data:image/svg+xml;base64,${emptyMapSvg})`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundPosition="center"
              borderRightRadius="lg"
            ></Box>
          )}
          {Boolean(storeId) && <StoreMap />}
        </Flex>
      </Flex>
      {hasStores && <NextButton currentBlock="store-search" />}
    </Wrapper>
  )
}

export default StoreSearchBlock
