import { Flex, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { scroller } from "react-scroll"
import { useAvailableStores } from "~/hooks/useAvailableStores"
import { useCart } from "~/hooks/useCart"
import { useSelectedStore } from "~/hooks/useSelectedStore"
import { actions } from "~/redux/process"
import StoreUpdateComfirmationModal from "../StoreUpdateConfirmationModal"
import { usePrinticularMapTemporaryCredentials } from "./hooks/usePrinticularMapTemporaryCredentials"
import MapAWS from "./MapAWS"
import StorePins from "./StorePins"

interface Props {}

const StoreMapAWS: React.FC<Props> = () => {
  const { credentials } = usePrinticularMapTemporaryCredentials()
  const dispatch = useDispatch()
  const { cartActions } = useCart()
  const { store: selectedStore } = useSelectedStore()
  const { stores: availableStores } = useAvailableStores()
  const [lineItemsToKeep, setLineItemsToKeep] = useState<EntityId[]>([])
  const [lineItemsToRemove, setLineItemsToRemove] = useState<EntityId[]>([])
  const { isOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure()

  const onOpen = useCallback(
    (data: { lineItemsToKeep: EntityId[]; lineItemsToRemove: EntityId[] }) => {
      setLineItemsToKeep(data.lineItemsToKeep)
      setLineItemsToRemove(data.lineItemsToRemove)
      openDialog()
    },
    [openDialog]
  )

  const onClose = useCallback(() => {
    closeDialog()
    setLineItemsToKeep([])
    setLineItemsToRemove([])
  }, [closeDialog])

  const onSelectStore = useCallback(
    (storeId: EntityId) => {
      cartActions.setStore({ storeId, onOpen, onClose })
      scroller.scrollTo(`StoreSearchListItem-${storeId}`, {
        containerId: "StoreSearchList",
        duration: 500,
        smooth: true,
      })
    },
    [cartActions, onClose, onOpen]
  )

  const onDialogDecision = useCallback(
    choice => {
      dispatch(actions.productCompatibilityDialog({ choice }))
    },
    [dispatch]
  )

  const onStoreClick = useCallback(
    (storeId: EntityId) => {
      onSelectStore(storeId)
    },
    [onSelectStore]
  )

  return (
    <Flex
      flex={3}
      height={{ base: selectedStore ? 320 : "full", md: "full" }}
      borderTopRightRadius={{ base: "none", md: "lg" }}
      borderBottomRightRadius={{ base: "none", md: "lg" }}
      overflow="hidden"
    >
      {selectedStore && credentials && (
        <MapAWS
          credentials={credentials}
          latitude={+selectedStore.latitude}
          longitude={+selectedStore.longitude}
        >
          <StorePins
            availableStores={availableStores}
            selectedStoreId={selectedStore.id}
            onClick={onStoreClick}
          />
        </MapAWS>
      )}

      <StoreUpdateComfirmationModal
        lineItemsToKeep={lineItemsToKeep}
        lineItemsToRemove={lineItemsToRemove}
        onClose={() => onDialogDecision(false)}
        onConfirm={() => onDialogDecision(true)}
        isOpen={isOpen}
      />
    </Flex>
  )
}

export default React.memo(StoreMapAWS)
